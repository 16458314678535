<template>
    <v-app>
        <v-card>
            <v-card-title>
                <span>{{oc}}</span>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="tableData"
                    :search="search"
                    v-model="selected"
                    no-results-text="No hay resultados disponibles"
                    no-data-text="No hay datos disponibles"
                    loading-text="Cargando datos..."
                    class="elevation-1 datatable"
                >
                    <template v-slot:item.unidad="{ item }">
                        <td class="text-center">
                            {{item.unit.name}}
                        </td>
                    </template>
                    <template v-slot:item.quantity_required="{ item }">
                        <td class="text-center">
                            {{item.quantity_required}} {{item.unit.name}}
                        </td>
                    </template>
                    <template v-slot:item.quantity_purchased="{ item }">
                        <td class="text-center">
                            {{item.quantity_purchased}} {{item.unit.name}}
                        </td>
                    </template>
                    <template v-slot:item.quantity_envased="{ item }">
                        <td class="text-center">
                            {{item.quantity_envased.toFixed(3)}} {{item.unit.name}}
                        </td>
                    </template>
                    <template v-slot:item.quantity_mermed="{ item }">
                        <td class="text-center">
                            {{item.quantity_mermed}} {{item.unit.name}}
                        </td>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <h4>Productos envasados</h4>
                <v-data-table
                    :headers="headersEnvasados"
                    :items="tableDataEnvasados"
                    :search="searchEnvasados"
                    v-model="selectedEnvasados"
                    no-results-text="No hay resultados disponibles"
                    no-data-text="No hay datos disponibles"
                    loading-text="Cargando datos..."
                    class="elevation-1 datatable"
                >
                    <template v-slot:item.nombre="{ item }">
                        <td class="text-center">
                            {{item.order_detail.name}}
                        </td>
                    </template>
                    <template v-slot:item.scaned="{ item }">
                        <td class="text-center">
                            <v-chip
                            v-if="item.scaned"
                                class="ma-2"
                                color="green"
                                text-color="white"
                            >
                                <v-avatar left>
                                <v-icon>mdi-checkbox-marked-circle</v-icon>
                                </v-avatar>
                                Escaneado
                            </v-chip>
                            <v-chip
                            v-if="!item.scaned"
                                class="ma-2"
                                color="red"
                                text-color="white"
                            >
                                No escaneado
                            </v-chip>
                        </td>
                    </template>
                    <template v-slot:item.unidad="{ item }">
                        <td class="text-center">
                            {{item.unit.name}}
                        </td>
                    </template>
                    <template v-slot:item.cantidad="{ item }">
                        <td class="text-center">
                            {{item.quantity.toFixed(3)}} {{item.unit.name}}
                        </td>
                    </template>
                    
                    <template v-slot:item.proveedor="{ item }">
                        <td class="text-center">
                            {{item.supplier.name}}
                        </td>
                    </template>
                    <template v-slot:item.creacion="{ item }">
                        <td class="text-center">
                            {{ $moment(item.created_at).format('YYYY-MM-DD h:mm:ss') }}
                        </td>
                    </template>
                    <template v-slot:item.vencimiento="{ item }">
                        <td class="text-center">
                            {{ item.expires_at ? $moment(item.expires_at).format('YYYY-MM-DD h:mm:ss') : 'No especificada' }}
                        </td>
                    </template>
                    <template v-slot:item.oc_referencia="{ item }">
                        <td class="text-center">
                            {{oc}}
                        </td>
                    </template>
                    <template v-slot:item.accion="{ item }">
                        <td class="text-center">
                            <v-btn @click="mermaComentario(item)" :disabled="loading" small color="primary" dark class="mr-2"
                            ><v-icon left dark>mdi-remove</v-icon>Asignar merma</v-btn>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
                    
        <v-dialog v-model="dialogComentario" persistent max-width="400px">
            
                <v-card>
                    <v-card-title>
                    <span class="headline">Motivo de mermar producto: {{itemPivot ? itemPivot.order_detail.name : ''}}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-select
                                    :items="['Motivo 1', 'Motivo 2', 'Motivo 3', 'Motivo 4']"
                                    label="Seleccione una opción*"
                                    v-model="motivoMerma"
                                    required
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogComentario = false">Cancelar</v-btn>
                        <v-btn color="blue darken-1" v-show="!!motivoMerma" text @click="mermOut">Enviar</v-btn>
                    </v-card-actions>
                </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
    data()
    {
        return {
            oc: undefined,
            headers: [
                {text: 'Nombre producto', value: 'product'},
                {text: 'SKU', value: 'sku'},
                {text: 'Unidad', value: 'unidad'},
                {text: 'Cantidad requerida', value: 'quantity_required'},
                {text: 'Cantidad comprada al proveedor', value: 'quantity_purchased'},
                {text: 'Cantidad envasada', value: 'quantity_envased'},
                {text: 'Cantidad merma', value: 'quantity_mermed'},
            ],
            tableData: [],
            search: '',
            selected: [],
            headersEnvasados: [
                {text: 'ID', value: 'id'},
                {text: 'Nombre producto', value: 'nombre'},//
                {text: 'Escaneado', value: 'scaned'},//
                {text: 'SKU Generico', value: 'generic_sku'},
                {text: 'SKU Custom', value: 'custom_sku'},
                {text: 'Cantidad', value: 'cantidad'},//
                {text: 'Unidad', value: 'unidad'},//
                {text: 'Proveedor', value: 'proveedor'},//
                {text: 'Fecha creación', value: 'creacion'},//
                {text: 'Fecha vencimiento', value: 'vencimiento'},//
                {text: 'OC referencia', value: 'oc_referencia'},//
                {text: 'Accion', value: 'accion'}
            ],
            tableDataEnvasados: [],
            searchEnvasados: '',
            selectedEnvasados: [],
            itemPivot: undefined,
            dialogComentario: false,
            motivoMerma: '',
        }
    },
    methods: {
        fetchData()
        {
            this.axios({
                method: 'POST',
                url: 'inventory/oc/'+this.$route.params.oc_ref+'/data',
                data: {
                    store: this.$store.state.passport.user.default_store_id
                }
            }).then( response => {
                this.tableData = response.data.pod;
                this.tableDataEnvasados = response.data.pp_oc;
            }).catch( error => {

            });
        },
        mermaComentario(item)
        {
            this.itemPivot = item;
            this.dialogComentario = true;
        },
        mermOut()
        {
            if( confirm('Estas seguro?') ){
                let item = this.itemPivot;
                this.dialogComentario = false;
                var vm = this;
                this.loading = true;
                this.axios({
                    url: 'inventory/merm_out',
                    method: 'POST',
                    data: {
                        pp: item.id,
                        store: this.$store.state.passport.user.default_store_id,
                        comment: this.motivoMerma
                    }
                }).then( response => {
                    vm.$bvToast.toast('El producto se envió a merma correctamente', {
                        title: `Notificación`,
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-center'
                    });
                    vm.fetchData();
                    vm.loading = false;
                }).catch( error => {
                    console.log(error);
                    vm.loading = false;
                });
            }
        }
    },
    created(){
        
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Ordenes de compra", route: "/ocs" },
            { title: "Orden de compra "+this.$route.params.oc_ref, route: "/oc/"+this.$route.params.oc_ref },
        ]);
        this.oc = this.$route.params.oc_ref;
        this.fetchData();
    },
    watch: {
        $route(to, from) {
            this.fetchData();
        }
    }
}
</script>