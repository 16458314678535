var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.oc))])]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headers,"items":_vm.tableData,"search":_vm.search,"no-results-text":"No hay resultados disponibles","no-data-text":"No hay datos disponibles","loading-text":"Cargando datos..."},scopedSlots:_vm._u([{key:"item.unidad",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.unit.name)+" ")])]}},{key:"item.quantity_required",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.quantity_required)+" "+_vm._s(item.unit.name)+" ")])]}},{key:"item.quantity_purchased",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.quantity_purchased)+" "+_vm._s(item.unit.name)+" ")])]}},{key:"item.quantity_envased",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.quantity_envased.toFixed(3))+" "+_vm._s(item.unit.name)+" ")])]}},{key:"item.quantity_mermed",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.quantity_mermed)+" "+_vm._s(item.unit.name)+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-divider'),_c('h4',[_vm._v("Productos envasados")]),_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headersEnvasados,"items":_vm.tableDataEnvasados,"search":_vm.searchEnvasados,"no-results-text":"No hay resultados disponibles","no-data-text":"No hay datos disponibles","loading-text":"Cargando datos..."},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.order_detail.name)+" ")])]}},{key:"item.scaned",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[(item.scaned)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle")])],1),_vm._v(" Escaneado ")],1):_vm._e(),(!item.scaned)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" No escaneado ")]):_vm._e()],1)]}},{key:"item.unidad",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.unit.name)+" ")])]}},{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.quantity.toFixed(3))+" "+_vm._s(item.unit.name)+" ")])]}},{key:"item.proveedor",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.supplier.name)+" ")])]}},{key:"item.creacion",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('YYYY-MM-DD h:mm:ss'))+" ")])]}},{key:"item.vencimiento",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.expires_at ? _vm.$moment(item.expires_at).format('YYYY-MM-DD h:mm:ss') : 'No especificada')+" ")])]}},{key:"item.oc_referencia",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.oc)+" ")])]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.loading,"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.mermaComentario(item)}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-remove")]),_vm._v("Asignar merma")],1)],1)]}}]),model:{value:(_vm.selectedEnvasados),callback:function ($$v) {_vm.selectedEnvasados=$$v},expression:"selectedEnvasados"}})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.dialogComentario),callback:function ($$v) {_vm.dialogComentario=$$v},expression:"dialogComentario"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Motivo de mermar producto: "+_vm._s(_vm.itemPivot ? _vm.itemPivot.order_detail.name : ''))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-select',{attrs:{"items":['Motivo 1', 'Motivo 2', 'Motivo 3', 'Motivo 4'],"label":"Seleccione una opción*","required":""},model:{value:(_vm.motivoMerma),callback:function ($$v) {_vm.motivoMerma=$$v},expression:"motivoMerma"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogComentario = false}}},[_vm._v("Cancelar")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.motivoMerma),expression:"!!motivoMerma"}],attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.mermOut}},[_vm._v("Enviar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }